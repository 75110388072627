import React from 'react'

const InstructorCard = ({data}) => {
  return (
    <>
        <div className='instructorCard'>
            <div className='instructorImg'>
                <img src={data.instructorAvatar} className=" graduate-picture instructorimg" alt="instructor"/>
            </div>
            <div className='instructorName'>
                {data.name}
            </div>
            <div className='instructorExp'>
                {data.experience}
            </div>
            <div className='instructorCompany'>
                <a href={data.url}><img src={data.compLogo} className="company-img" alt='comapny'/></a>
            </div>
        </div>
    </>
  )
}

export default InstructorCard