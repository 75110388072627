import React from 'react'
import tick from "../../assets/tick.png";

const LearnCard = ({data}) => {
  return (
    <>
        <div className='learn-wrap'>
            <div className='tickImg'>
                <img src={tick} alt="tick"/>
            </div>
            <div className='learn-text'>
                {data.text}
            </div>
        </div>
    </>
  )
}

export default LearnCard