import React from "react";

const PartnerCard = ({data}) => {
  return (
    <>
      <div className="partner-card">
        <img src={data.src} alt={data.alt} className="partner-logo" />
      </div>
    </>
  );
};

export default PartnerCard;
