import React from 'react'
import {Link} from 'react-router-dom'
import play from "../../assets/play.png"
import "./Event.css";


const PastEventCard = ({data}) => {
  return (
    <>
      <div className="past-card">
        <div className="event-image"><img src={data.src} alt="event-img" className="pasteventImg"/></div>
        <div className="event-body" style={{width:"100%"}}>
          <div className="event-date">{data.date}</div>
          <div className="event-title" style={{fontWeight:"700",fontSize:"20px", lineHeight:"25px"}}>{data.title}</div>
          <div className="event-creator">
          <img src={data.creatorAvatar} alt="creator" className='creator-avatar'/> by {data.creator}</div>
          <div className="event-register" style={{marginTop:"30px"}}>
            <Link to={data.link} className="card-link">
            <button type="button" className="watchBtn"> <img src={play} alt="play" style={{width:"20px",height:"20px",margin: "auto 6px"}}/> Watch Replay </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default PastEventCard