import React from "react";
import SocialLogo1 from "../../assets/social (6).svg";
import SocialLogo2 from "../../assets/social (5).svg";
import SideBar from "./SideBar";
const InstructorCard = ({ data }) => {
  var socials = [{ imgSrc: SocialLogo1 }, { imgSrc: SocialLogo2 }];
  const data_key = data.instructor;
  return (
    <>
      <div className="instructor-card">
        <div className="img-wrapper">
          <img src={data_key.avatar} alt="instructor" className="instructor-img" />
        </div>
        <div className="instructor-details">
          <div className="card-title">INSTRUCTOR</div>
          <div className="instructor-name">{data_key.name}</div>
          <div className="instructor-bio">{data_key.bio}</div>
          <div className="card-footer">
            {socials.map((social, i) => {
              return (
                <a
                  href={data_key.social[i]}
                  key={i}
                  style={{ margin: "10px 5px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={social.imgSrc}
                    alt="Social Media Logo"
                    className="social-logo"
                  />
                </a>
              );
            })}
          </div>
        </div>
        <SideBar msk={data.eventDetails} />
      </div>
    </>
  );
};

export default InstructorCard;
