import React, { useState } from 'react'
import minus from "../../assets/minus.png";
import plus from "../../assets/plus.png";
const QuestionCard = ({data}) => {
    const [expanded,setExpanded] = useState(false);
  return (
    <>
        <div className='question-card' onClick={()=>{setExpanded(!expanded)}}>
            <div className='card-top'>
                <div className='question'>{data.question}</div>
                <div className='logo'>
                    <img src={expanded?minus:plus} alt="logo"/>
                </div>
            </div>
            <div className='answer' style={{display: expanded? 'flex' :'none'}}>
                {data.answer}
            </div>
        </div>
    </>
  )
}

export default QuestionCard