import React from 'react'
import arrow from "../../assets/Arrow.png"
import clock from "../../assets/clock.png"
import bell from "../../assets/bell.png"
import calendar from "../../assets/calendar.png"
import "./Event.css";

const UpcomingEventCard =({data}) => {
  return (
    <>
      <div className="upcoming-card">
        <div className="event-image"><img src={data.img} alt="event-img" className="event-img"/></div>
        <div className="event-body">
          <div className="event-date">{data.date}</div>
              <div className="event-title">{data.title}</div>
          <div className="event-subtitle">{data.subtitle}</div>
          <div className="event-detail">
            <div className="event-duration">
            <img src={clock} alt="clock" className="card-icon" style={{margin:"2px"}}/> {data.duration}</div>
            <div className="event-trial">
            <img src={bell} alt="bell"  className="card-icon" style={{margin:"2px"}}/> {data.trial}</div>
            <div className="event-starting">
            <img src={calendar} alt="calendar"  className="card-icon" style={{margin:"2px"}}/>Starting from {data.starting}</div>
          </div>
          <div className="event-creator">
          <img src={data.creatorAvatar} alt="creator" className="creator-avatar"/> by {data.creator}</div>
          <a href={data.formLink} target="_blank" rel='noreferrer' className='card-link'>
            <div className="event-register">
              <button type="button" className="registerBtn">Regsiter Now <img src={arrow} alt="arrow" style={{margin:"5px"}}/></button>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default UpcomingEventCard