import React from "react";

const BenefitCard = ({data}) => {
  return (
    <>
      <div className="benefitCard">
        <div className="card-ico">
          <img src={data.src} alt="benefit" />
        </div>
        <div className="card-benefit">{data.benefit}</div>
        <div className="benefit-description">
          {data.description}
        </div>
      </div>
    </>
  );
};

export default BenefitCard;
