import React , {useState,useEffect} from "react";
import gradPic1 from "../../assets/grad (1).jpeg";
import gradPic2 from "../../assets/grad (2).jpeg";
import gradPic3 from "../../assets/grad (3).jpeg";
import gradPic4 from "../../assets/grad (4).jpeg";
import gradPic5 from "../../assets/grad (5).jpeg";
import gradPic6 from "../../assets/grad (6).jpeg";
import heartMess from "../../assets/messenger-heart.png";
import mess from "../../assets/messenger.png";
import graduateData from './GraduatesData.json';
const GraduationSecton = () => {
  const [dataSelector , setDataSelector] = useState(1);
  // Do not delete the empty object in GraduatesData.json : Causes Array Out of Bounds error
  const carousel = (ds) =>{
    if(ds<=4){
      document.getElementById(`gradSelect-${ds>1?ds-1:0}`).classList.remove('selector-filled');
      document.getElementById(`gradSelect-${ds}`).classList.add('selector-filled');
      setDataSelector(dataSelector+1);
    }else{
      document.getElementById(`gradSelect-4`).classList.remove('selector-filled');
      setDataSelector(1);
      document.getElementById(`gradSelect-0`).classList.add('selector-filled');
    }
  }
  useEffect(()=>{
    const intId = setInterval(() => {
      carousel(dataSelector);
    }, 5000);
    return function cleanup(){
        clearInterval(intId);
    }
  })
  return (
    <>
      <div className="graduates-section">
        <h1 className="graduate-heading">
          Our Graduates <img src={heartMess} className="decorates" alt="" />
        </h1>
        <div className="graduate-card-holder">
          <div className="graduate-card-base">
            <div className="card-base">
              <div className="card-dots">
                <img src={heartMess} alt="" className="decorates" />
                <img
                  src={gradPic6}
                  alt=""
                  className="self-align-end extra-setting graduate-picture"
                />
              </div>
              <div className="card-dots">
                <img src={gradPic5} alt="" className="graduate-picture plus" />
              </div>
              <div className="card-dots">
                <img src={mess} alt="" className="decorates" />
                <img
                  src={gradPic4}
                  alt=""
                  className="self-align-end graduate-picture plus"
                />
              </div>
            </div>
          </div>
          <div className="graduate-card">
            <img
              src={graduateData[dataSelector].avatar}
              alt=""
              className="graduate-picture"
            />
            <h2 className="graduate-name">{graduateData[dataSelector].name}</h2>
            <p className="graduate-testimonial">
              {graduateData[dataSelector].testimonial}
            </p>
            {/* <img src={graduateData[dataSelector].companyLogo} alt="" className="graduate-company" /> */}
            <div className="graduate-slider">
              <span
                className="selector-blank selector-filled"
                id="gradSelect-0"
              ></span>
              <span className="selector-blank" id="gradSelect-1"></span>
              <span className="selector-blank" id="gradSelect-2"></span>
              <span className="selector-blank" id="gradSelect-3"></span>
              <span className="selector-blank" id="gradSelect-4"></span>
            </div>
          </div>
          <div className="graduate-card-base">
            <div className="card-base" style={{ marginLeft: "auto" }}>
              <div className="card-dots">
                <img src={mess} alt="" className="decorates" />
                <img
                  src={gradPic1}
                  alt=""
                  className="extra-setting graduate-picture"
                />
              </div>
              <div className="card-dots">
                <img
                  src={gradPic2}
                  alt=""
                  className="self-align-end graduate-picture plus"
                />
              </div>
              <div className="card-dots">
                <img src={gradPic3} alt="" className="graduate-picture plus" />
                <img src={heartMess} alt="" className="decorates" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GraduationSecton;
