import React, { useEffect } from "react";
import "./Event.css";
import UpcomingEventCard from "./UpcomingEventCard.js";
import upcomingEventData from "./UpcomingEventData.json";
import PastEventCard from "./PastEventCard.js";
import pastEventData from "./PastEventData.json";
const Events = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="events-section-2">
        <div className="event-banner">
          <div className="banner-blur banner-blur-s">
            Events and Bootcamp
            <span
              style={{
                fontSize: "14px",
                fontWeight: "400",
                width: '90%'
              }}
              className="event-subhead"
            >
              Join these amazing bootcamps to learn new in-demand
              technologies with hands on projects in each bootcamp
              along with certificates from DevTown, MLSA and AWS.
            </span>
          </div>
        </div>
      </div>
      <div className="event-container">
        <div className="upcomingEvents">
          <div className="event-head">Upcoming Events</div>
          <div className="event-listing">
            {upcomingEventData.map((course, index) => {
              return <UpcomingEventCard data={course} key={index} />;
            })}
          </div>
        </div>
        <div className="pastEvents">
          <div className="event-head">Past Events</div>
          <div className="pastevent-listing">
            {pastEventData.map((event, index) => {
              return <PastEventCard data={event} key={index} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
