import React, { useEffect , useRef } from "react";
import PartnerCard from "./PartnerCard";
import partnerData from "./PartnerCard2.json";
const PartnerSection = () => {
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    const handleClick = event => {
      event.preventDefault();
      element.scrollLeft += event.deltaY;
    };
    element.addEventListener('wheel', handleClick);
    return () => {
      element.removeEventListener('wheel', handleClick);
    };
  }, []);

  return (
    <>
      <div className="partner-section">
        <h1 className="partner-label">Instructors from</h1>
        <div className="partner-card-slider" ref={ref}>
          {partnerData.map((partner, index) => {
            return <PartnerCard data={partner} key={index} />;
          })}
        </div>
      </div>
    </>
  );
};

export default PartnerSection;
