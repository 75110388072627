import React from "react";
// import download from "../../assets/download.png";
import CourseCard from "./CourseCard";
import detail1 from "../../assets/detail1.png";
import detail2 from "../../assets/detail2.png";
import detail3 from "../../assets/detail3.png";
import Details from "../PreviousBootcamp/Details";
const SideBar = ({data}) => {
  return (
    <>
      <div className="sidebar">
        <div className="sidebar-header">
          Course Syllabus
          {/* <a href={data.downloadLink}>
            <img src={download} alt="download" className="download-image" />
          </a> */}
        </div>
        <div className="sidebar-bottom">
          <div className="sidebar-detail">
            {data.syllabus.map((info, index) => {
              return <CourseCard data={info} key={index} />;
            })}
          </div>
          <div className="details-listing">
            <Details
              img={detail1}
              heading="Type"
              subheading={data.courseCategory}
            />
            <Details
              img={detail2}
              heading="Location"
              subheading={data.deliveryMode}
            />
            <Details
              img={detail1}
              heading="Prerequisites"
              subheading={data.requisites}
            />
            <Details
              img={detail3}
              heading="Certification"
              subheading={data.isCertifiable}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
