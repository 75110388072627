import React, { useEffect } from "react";
import "./HomePage.css";
// import HomeBanner from "./HomeBanner";
import EventBanner from './EventBanner/EventBanner';
import CertificateSection from "./CertificateSection";
import LearnSection from "./LearnSection";
import PartnerSection from "./PartnerSection/PartnerSection";
import GraduationSection from "./GraduationSecton";
import BottomBanner from "./BottomBanner/BottomBanner";
import DetailSection from "./DetailSection/DetailSection";
const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <EventBanner/>
      <LearnSection />
      <PartnerSection />
      <CertificateSection />
      <DetailSection />
      <GraduationSection />
      <BottomBanner />
    </>
  );
};

export default HomePage;
