import React from "react";
// import {Link} from 'react-router-dom' 
const BottomBanner = () => {
  return (
    <>
      <div className="banner-bottom">
        <div className="banner-bottom-graphic">
          <h1 className="banner-bottom-text">
            Learn Machine Learning, Data <br />
            Science, Full-Stack Web <br />
            Development and much more!
          </h1>
          <a href="https://www.youtube.com/channel/UCt4EU38IOgU9ww1Yrq_n-vA">
            <button className="banner-button-alt button-patch">
              Explore now
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default BottomBanner;
