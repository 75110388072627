import React from "react";

const BatchesCard = ({data}) => {
  // Date format for the Time in Json Should Be "Jan 5, 2024 15:37:25" Strictly
  var countDownDate = new Date(data.countdown).getTime();
  var timer = setInterval(()=> {
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
    if (distance < 0) {
      clearInterval(timer);
      document.getElementById("days").innerHTML = "0";
      document.getElementById("hours").innerHTML = "0";
      document.getElementById("minutes").innerHTML = "0";
      document.getElementById("seconds").innerHTML = "0";
    }else{
      document.getElementById("days").innerHTML = days;
      document.getElementById("hours").innerHTML = hours;
      document.getElementById("minutes").innerHTML = minutes;
      document.getElementById("seconds").innerHTML = seconds;
    }
  }, 1000);
  return (
    <>
      <div className="batchcard-top">
        <div className="available">Available Batches</div>
        <div className="nextbatch">NEXT BATCH STARTS IN</div>
      </div>
      <div className="batchcard-foot">
        <div className="date">ENROLL FOR {data.batch1}</div>
        <div className="date">ENROLL FOR {data.batch2}</div>
        <div className="timer">
          <div className="wrap">
            <div className="number" id="days"></div>
            <div className="type">DAYS</div>
          </div>
          <div className="wrap">
            <div className="number" id="hours"></div>
            <div className="type">HRS</div>
          </div>
          <div className="wrap">
            <div className="number" id="minutes"></div>
            <div className="type">MINS</div>
          </div>
          <div className="wrap">
            <div className="number" id="seconds"></div>
            <div className="type">SECS</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchesCard;
