import React from "react";

const AboutUs = () => {
  return (
    <>
      <div class="detail-section">
        <div class="figure-section">
          <h1 class="figure-heading">About Us</h1>
          <span style={{ textAlign: "center", fontSize: "24px" }}>
            At{" "}
            <span style={{ color: "#6f1651", fontWeight: 600 }}>
              Code Sprint
            </span>
            , we're here to help you <b>become a pro</b> in skills that matter
          </span>
          <div class="figure-subheading" style={{ width: "60%" }}>
            Our courses blend theory with hands-on workshops, where you tackle
            real world projects. This combo not only boosts your skills but also
            opens doors to new career paths. We cover all the latest tech
            trends, sharing insider knowledge from industry whizzes. Forget
            boring lectures; we're all about diving into projects headfirst.
          </div>
          <div
            class="figure-visuals"
            style={{ marginTop: "60px", marginBottom: "60px" }}
          >
            <div className="figure-area">
              <div className="figure-card">
                <h1 className="figure-number">13K+</h1>
                Youtube Community
              </div>
              <div className="figure-card">
                <h1 className="figure-number">25K+</h1>
                Happy Learners
              </div>
              <div className="figure-card">
                <h1 className="figure-number">100+</h1>
                Webinar and Sessions
              </div>
            </div>
          </div>
        </div>
        <div class="figure-section">
          <h1 class="figure-heading">Our Team</h1>
          <span style={{ margin:'auto',width:'60%',textAlign: "center", fontSize: "24px" }}>
            <span style={{ color: "#6f1651", fontWeight: 600 }}>
              "Quality over Quantity"
            </span>&nbsp;
            is our motto, always striving to give you the <b>best learning journey</b> in various fields.
          </span>
          <img src="/assets/sprintex/about-us.png" style={{width:'80%',margin:'50px auto', border:'5px solid #6f1651',borderRadius:'10px'}} alt="team" />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
