import React from "react";
import "./PreviousBootcamp.css";
const ScheduleCard = ({ data }) => {
  return (
    <>
      <a href={data.link} target="_blank" rel="noopener noreferrer">
        <div className="schedule-card">
          <div className="header-section">
            <img src={data.banner} alt="play" className="play-img" />
          </div>
          <div className="detail-section-alt">
            <div className="video-detail">{data.detail}</div>
            <div className="video-description">{data.description}</div>
            <div className="video-description">{data.duration}</div>
          </div>
        </div>
      </a>
    </>
  );
};

export default ScheduleCard;
