import React from "react";
import "./DetailCourses.css";

const CostingCard = ({cost}) => {
  return (
    <>
      <div className="program-cost">
        <div className="head1">₹{cost.cost}</div>
        <div className="head2">Including 18% GST</div>
      </div>
      <a href={cost.enrollLink}>
        <div className="program-enroll">
          <button type="button" className="enrollBtn" alt="Enroll">
            Enroll Now
          </button>
        </div>
      </a>
      {/* <a href={cost.downloadLink}>
        <div className="syllabus-download">
          <button type="button" className="downloadBtn" alt="Download">
            Download Syllabus
          </button>
        </div>
      </a> */}
    </>
  );
};

export default CostingCard;
