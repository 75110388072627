import React from "react";
import { Link } from "react-router-dom";
import LogoLarge from "../../assets/logo-2x.png";
import SocialLogo4 from "../../assets/social (1).svg";
import SocialLogo1 from "../../assets/social (2).svg";
import SocialLogo2 from "../../assets/social (3).svg";
import SocialLogo3 from "../../assets/social (4).svg";
// import SocialLogo5 from "../../assets/social (9).svg";
// import Submit from '../../assets/submit.png'
import "./Footer.css";
const Footer = () => {
  var socials = [
    {
      imgSrc: SocialLogo1,
      clickLink: "https://www.linkedin.com/company/code-sprint/",
    },
    {
      imgSrc: SocialLogo2,
      clickLink: "https://chat.whatsapp.com/F0T8dUrKV5OBqBcGDqU8gl",
    },
    {
      imgSrc: SocialLogo3,
      clickLink: "https://www.youtube.com/channel/UCt4EU38IOgU9ww1Yrq_n-vA",
    },
    {
      imgSrc: SocialLogo4,
      clickLink: "https://www.instagram.com/codesprint.in/",
    },
    // { imgSrc: SocialLogo5, clickLink: "#" },
  ];
  return (
    <>
      <div className="footer">
        <div className="footer-section">
          <div className="foot-logo">
            <img
              src={LogoLarge}
              alt="Code Sprint Logo-Footer"
              className="header-logo head-alt"
            />
          </div>
          <p className="company-motto">
            We at Code Sprint look forward to provide top-level educational
            opportunities and help students advance their careers in the Tech
            Industry.
          </p>
          <div className="foot-social">
            {socials.map((social, i) => {
              return (
                <a
                  href={social.clickLink}
                  key={i}
                  style={{ margin: "10px 5px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={social.imgSrc}
                    alt="Social Media Logo"
                    className="social-logo"
                  />
                </a>
              );
            })}
          </div>
        </div>
        <div className="footer-section">
          <div className="list-head">
            <b>Menu</b>
          </div>
          <Link
            className="link-ov"
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <div className="list-item">Home</div>
          </Link>
          {/* <Link className="link-ov" to="/courses">
            <div className="list-item">Courses</div>
          </Link> */}
          <Link className="link-ov" to="/events">
            <div className="list-item">Events</div>
          </Link>
          <Link className="link-ov" to="/store">
            <div className="list-item">Store</div>
          </Link>
        </div>
        <div className="footer-section">
          <div className="list-head">
            <b>Resources</b>
          </div>
          <a href="mailto:hello@codesprint.in" className="link-ov">
            <div className="list-item">Help & Support</div>
          </a>
          <Link
            to="/"
            className="link-ov"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <div className="list-item">About Us</div>
          </Link>
          <Link to="/terms" className="link-ov">
            <div className="list-item">Terms & Conditions</div>
          </Link>
          <Link to="events" className="link-ov">
            <div className="list-item">Activity</div>
          </Link>
        </div>
        {/* <div className="footer-section">
          <label htmlFor="subscribe" className="label-subs">
            Subscribe Us
          </label>
          <div className="input-bind">
            <input
              type="text"
              name="subscribe"
              id="subscribe"
              className="subscribe-inp"
              placeholder="info@yourgmail.com"
            />
            <button className="subscribe-btn"><img src={Submit} alt="" className="submit-ico"/></button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Footer;
