import React, { useEffect } from "react";
import "./StoreSection.css";
import StoreItem from "./StoreItem";
import storeData from "./StoreData.json";
const StoreSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="store-section">
        <div className="banner-section-store">
          <div className="banner-blur">
            BRING YOUR FAVOURITE ACCESSORIES
            <br />
            HOME TODAY
          </div>
        </div>
        <div className="store-container">
          <h1 className="store-head">All Categories</h1>
          <div className="store-listing">
            {storeData.map((data, index) => {
              return <StoreItem data={data} key={index} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreSection;
