import React, { useState,useEffect } from 'react'
// import arrowL from '../../assets/arrow-alt-l.png';
// import arrowR from '../../assets/arrow-alt-r.png';
import cardGraphicB from '../../assets/learn-card-graphic-b.png';
import cardGraphicS from '../../assets/learn-card-graphic-s.png';
import cardGraphicB1 from '../../assets/learn-card-graphic-b-1.png';
import cardGraphicS2 from '../../assets/learn-card-graphic-s-1.png';
const LearnSection = () => {
  const [dataSelector,setDataSelector] = useState(1);
  const carousel = (ds) =>{
    if(ds<=3){
      document.getElementById(`learnSelect-${ds>1?ds-1:0}`).classList.remove('selector-filled');
      document.getElementById(`learnSelect-${ds}`).classList.add('selector-filled');
      document.getElementById(`learn-card-${ds>1?ds-1:0}`).classList.add('card-hide');
      document.getElementById(`learn-card-${ds}`).classList.remove('card-hide');
      setDataSelector(dataSelector+1);
    }else{
      document.getElementById(`learnSelect-3`).classList.remove('selector-filled');
      document.getElementById(`learn-card-3`).classList.add('card-hide');
      setDataSelector(1);
      document.getElementById(`learnSelect-0`).classList.add('selector-filled');
      document.getElementById(`learn-card-0`).classList.remove('card-hide');
    }
  }
  useEffect(()=>{
    const intId = setInterval(() => {
      carousel(dataSelector);
    }, 5000);
    return function cleanup(){
        clearInterval(intId);
    }
  })
  return (
    <>
      <div className="learn-section">
        {/* <div className="learn-info">
          <h1 className="learn-info-title">Why learn with us?</h1>
          <p className="learn-info-subtitle">
            Learn Artificial Intelligence and Full-<br/>Stack Web Development from
            people<br/> at Google, Goldman Sachs, American<br/> Express, Jio and
            Microsoft.
          </p>
           <div className="learn-control">
            <button className="learn-control-btn" onClick={()=>{minusCarousel(dataSelector)}}>
                <img src={arrowL} alt="Arrow Left" className="btn-ico"/>
            </button>
            <button className="learn-control-btn" onClick={()=>{plusCarousel(dataSelector)}}>
                <img src={arrowR} alt="Arrow Right" className="btn-ico"/>
            </button>
          </div>
        </div> */}
        <div className="learn-visual">
          <h1 className="learn-info-title">Why learn with us?</h1>
          <div className="learn-cards" id="learn-card-0">
            <div className="learn-card-big">
              <img
                src={cardGraphicB}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">Experienced Instructors</div>
              <p className="learn-card-desc">
                Learn from instructors who have a wealth of knowledge and
                expertise in their subject matter, and are also experts in
                creating a supportive learning environment that helps in growth
                and development.
              </p>
            </div>
            <div className="learn-card-big">
              <img
                src={cardGraphicS}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">Top Notch MasterClasses</div>
              <p className="learn-card-desc">
                Learn from the industry experts and top professionals in their
                respective fields to gain in-depth knowledge and hands-on
                experience in their chosen field.
              </p>
            </div>
          </div>
          <div className="learn-cards card-hide" id="learn-card-1">
            <div className="learn-card-big">
              <img
                src={cardGraphicB1}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">Industry-relevant learning</div>
              <p className="learn-card-desc">
                Focuses on providing learners with the tools, knowledge, and
                experiences needed to succeed in their chosen field.
              </p>
            </div>
            <div className="learn-card-big">
              <img
                src={cardGraphicS2}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">Expert Mentorship</div>
              <p className="learn-card-desc">
                Learn from experienced and knowledgeable professionals who
                provide guidance, support, and advice.
              </p>
            </div>
          </div>
          <div className="learn-cards card-hide" id="learn-card-2">
            <div className="learn-card-big">
              <img
                src={cardGraphicB}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">Large Student Community</div>
              <p className="learn-card-desc">
                Get enrolled to get several benefits such as access to quality
                education, structured learning, certification and support.
              </p>
            </div>
            <div className="learn-card-big">
              <img
                src={cardGraphicS}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">Guidance for Internships</div>
              <p className="learn-card-desc">
                Help you apply the concepts you learned to real-world
                situations, develop new skills, and build your professional
                network.
              </p>
            </div>
          </div>
          <div className="learn-cards card-hide" id="learn-card-3">
            <div className="learn-card-big">
              <img
                src={cardGraphicB1}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">Top-Notch LIVE Lectures</div>
              <p className="learn-card-desc">
                Join our three-month-long series of LIVE lectures with top
                mentors, recorded sessions, and teaching assistants.
              </p>
            </div>
            <div className="learn-card-big">
              <img
                src={cardGraphicS2}
                alt="Graphic of Calendar"
                className="learn-card-pic"
              />
              <div className="learn-card-title">One-on-One Mentorship</div>
              <p className="learn-card-desc">
                Students will have mentors who will help them with their doubts,
                provide guidance, and help them out 24x7.
              </p>
            </div>
          </div>
          <div className="learn-slider">
            <span className="selector-blank" id="learnSelect-0"></span>
            <span className="selector-blank" id="learnSelect-1"></span>
            <span className="selector-blank" id="learnSelect-2"></span>
            <span className="selector-blank" id="learnSelect-3"></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default LearnSection