import React, { useState } from "react";
import "./Header.css";
import hamIco from '../../assets/ham-ico.png'
// import wpL from '../../assets/wp.png'
import { useLocation , Link } from "react-router-dom";
// import Search from "../../assets/search.png";
import Logo from "../../assets/logo-2023.png";
const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [expanded,setExpanded] = useState(false);
  return (
    <>
      {/* <a
        href="https://chat.whatsapp.com/F0T8dUrKV5OBqBcGDqU8gl"
        className="community-header"
      >
        <span style={{ fontSize: "14px" }}>
          <img
            alt="Whatsapp"
            src={wpL}
            style={{
              width: "22px",
              height: "22px",
              marginRight: "10px",
              objectFit: "cover",
              verticalAlign: "middle",
            }}
          />
          Join our Private{" "}
          <span style={{ color: "#6f1651" }}>
            <span style={{ fontWeight: "bold" }}>WhatsApp</span>
          </span>{" "}
          group to never miss an update from code sprint
        </span>
      </a> */}
      <div className="header">
        <div className="header-R">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img src={Logo} alt="Main Site Logo" className="header-logo" />
          </Link>
        </div>
        <div className="header-L">
          <Link to="/">
            <div
              className={`header-option ${pathname === "/" ? "selected" : ""}`}
            >
              Home
            </div>
          </Link>
          <Link to="/courses">
            <div
              className={`header-option ${
                pathname.includes("/courses") ? "selected" : ""
              }`}
            >
              Courses
            </div>
          </Link>
          <Link to="/events">
            <div
              className={`header-option ${
                pathname.includes("/events") ? "selected" : ""
              }`}
            >
              Events
            </div>
          </Link>
          <Link to="/store">
            <div
              className={`header-option ${
                pathname === "/store" ? "selected" : ""
              }`}
            >
              Store
            </div>
          </Link>
          <Link to="/about-us">
            <div
              className={`header-option ${
                pathname === "/about-us" ? "selected" : ""
              }`}
            >
              About Us
            </div>
          </Link>
          {/* <button className="header-login">Login</button>
            <img src={Search} alt="Search icon" className="header-search"/> */}
        </div>
        <div
          className="header-ham"
          onClick={() => {
            setExpanded(true);
          }}
        >
          <img src={hamIco} alt="hambubrger icon" className="ham-ico" />
        </div>
        <div
          className="menu-blur"
          style={{ display: expanded ? "block" : "none" }}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <div className="header-menu">
            <Link
              to="/"
              className={`header-option ${pathname === "/" ? "selected" : ""}`}
            >
              <div>Home</div>
            </Link>
            <Link
              to="/courses"
              className={`header-option ${
                pathname.includes("/courses") ? "selected" : ""
              }`}
            >
              <div>Courses</div>
            </Link>
            <Link
              to="/events"
              className={`header-option ${
                pathname.includes("/events") ? "selected" : ""
              }`}
            >
              <div>Events</div>
            </Link>
            <Link
              to="/store"
              className={`header-option ${
                pathname === "/store" ? "selected" : ""
              }`}
            >
              <div>Store</div>
            </Link>
            <Link
              to="/about-us"
              className={`header-option ${
                pathname === "/about-us" ? "selected" : ""
              }`}
            >
              <div>About Us</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
