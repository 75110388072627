import React from "react";
// import Certificate from "../../assets/certificate.png";
const CertificateSection = ({link ,img}) => {
  return (
    <div className="certificate-section">
      <div className="certificate-content">
        <div className="certificate-name">
          Industrial <br />
          Certificates
        </div>
        <div className="certificate-info">
          Completion of the Training Program will get you a certificate of
          completing the training, and top students from each batch will get
          letter of recommendation from us
        </div>
        <div
          className="program-enroll"
          style={{
            width: "100%",
            marginTop: "20px",
            alignItmes: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <a href={link}>
            <button
              type="button"
              className="enrollBtn"
              alt="Enroll"
              style={{ width: "243px", height: "65px" }}
            >
              Enroll Now
            </button>
          </a>
        </div>
      </div>
      <div className="certificate-img">
        <img src={img} className="certificate" alt="certificate" />
      </div>
    </div>
  );
};

export default CertificateSection;
