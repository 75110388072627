import React from "react";
// import {Link} from 'react-router-dom'
// import arrowR from "../../../assets/arrow-right.png";
// import CourseCard from "./CourseCard";
// import courseData from './CourseData.json'
// import EventCard from "./EventCard";
// import eventData from './EventData.json'
const DetailSection = () => {
  return (
    <>
      <div className="detail-section">
        {/* Figure Section (Visual Keyword: 1.5M+ Happy Customers )*/}
        <div className="figure-section">
          <h1 className="figure-heading">Some figures that matter</h1>
          <div className="figure-subheading">
            Learn Artificial Intelligence and Full-Stack Web Development from
            people
          </div>
          <div className="figure-visuals">
            <div className="figure-area">
                <div className="figure-card">
                  <h1 className="figure-number">13K+</h1>
                  Youtube Community
                </div>
                <div className="figure-card">
                  <h1 className="figure-number">25K+</h1>
                  Happy Learners
                </div>
                <div className="figure-card">
                  <h1 className="figure-number">100+</h1>
                  Webinar and Sessions
                </div>
              </div>
          </div>
        </div>
        {/* Figure Section End */}
        {/* Explore Section (Visual Keyword: Explore Courses) */}
        <div className="explore-section">
          {/* <div className="course-section" id="courses">
            <div className="course-header">
              <h1 className="course-heading">Explore Courses</h1>
              <div className="course-view-btn">
                <Link to="/courses">
                  View All <img src={arrowR} alt="" className="btn-ico" />
                </Link>
              </div>
            </div>
            <div className="course-list">
              {courseData.map((course, index) => {
                return <CourseCard data={course} key={index} />;
              })}
            </div>
          </div> */}
          {/* <div className="event-section">
            <div className="course-header">
              <h1 className="course-heading">Events and Bootcamps</h1>
            </div>
            <div className="event-list"> */}
              {/* Mappings By EventData.json */}
              {/* Keep Minimum 2 Events in the list in order for design to appear best */}
              {/* {eventData.map((event, index) => {
                return <EventCard data={event} key={index} />;
              })} */}
            {/* </div>
          </div> */}
        </div>
        {/* End of Explore Section */}
      </div>
    </>
  );
};

export default DetailSection;
