import React from 'react'
// import './PreviousBootcamp.css';
const Details = (props) => {
  return (
    <>
        <div className='details'>
            <div className='detailImg'>
              <img src={props.img} className="detail-img" alt='detailImg'/>  
            </div>
            <div className='details-body'>
                <div className='heading'>{props.heading}</div>
                <div className='subheading'>{props.subheading}</div>
            </div>
        </div>
    </>
  )
}

export default Details