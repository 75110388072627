import React from "react";
import Certificate from "../../assets/homepage-certi.jpg";
const CertificateSection = ({link ,img}) => {
  return (
    <div className="certificate-section">
      <div className="certificate-content">
        <div className="certificate-name">
          Startup India Recognised
        </div>
        <div className="certificate-info">
          We are registered by govt of India and recognised by startup India and
          MSME.
          
        </div>
        {/* <div
          className="program-enroll"
          style={{
            width: "100%",
            marginTop: "20px",
            alignItmes: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <a href={link}>
            <button
              type="button"
              className="enrollBtn"
              alt="Enroll"
              style={{ width: "243px", height: "65px" }}
            >
              Enroll Now
            </button>
          </a>
        </div> */}
      </div>
      <div className="certificate-img">
        <img src={Certificate} className="certificate" alt="certificate" />
      </div>
    </div>
  );
};

export default CertificateSection;
