import React , {useEffect} from "react";
import "./AllCourses.css";
import allCourseData from './AllCourseData.json'
import UpcomingCard from "./EnrollCard"

const AllCourses = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <>
      <div className="allcourses-section">
        <div className="allcourses-banner">
         <div className="allcourses-btn">
            All Courses
         </div>
        </div>
      </div>
      <div className="event-container">
          <div className="event-listing">
            {allCourseData.map((course,index)=>{
              return <UpcomingCard data={course} key={index}/>
            })}
          </div>
        </div>
    </>
  );
};

export default AllCourses;
