import React , {useEffect} from "react";
import {useParams} from 'react-router-dom';
import SideBar from "./SideBar";
import ChooseUs from "./ChooseUs";
import BatchCard from "./BatchesCard.js";
import CertificateSection from "./CertificateSection";
import LearnCard from "./LearnCard.js";
import learnData from "./LearnData.json";
import QuestionCard from "./QuestionCard.js";
import questionData from './QuestionData.json';
import CostingCard from "./CostingCard";
import InstructorCard from "./InstructorCard";
import instructorData from "./InstructorData.json"
import BenefitCard from "./BenefitCard";
import benefitData from "./BenefitData.json";
import detailCourseData from './DetailCourseData.json';
import "./DetailCourses.css";
import "../PreviousBootcamp/PreviousBootcamp.css";
const DetailCourses = () => {
    useEffect(()=>{
      window.scrollTo(0,0);
    },[])
    /*In order to fetch Courses Correctly , This Variable Id will be used 
    This Needs to be defined in Events Link in Every Course Card Correctly
    and needs the key to be existing in the DetailCourseData.json
    any mismatch in cordination of courseId and Data in DetailCourseData.json
    can lead to catastrophic Events to the website */
    let { courseId }= useParams(); //Datatype Should be Integer Only , Initally must be (Int) 0 
    //Range of detailCourseData.length() and Respective of Link Accessed
    let masterKey = detailCourseData[courseId];
  if(masterKey===undefined){
      return(
      <>
      <center>
        <h1>Course is Not yet Published or There's Been a Mistake, Please Contact Admin!</h1>
      </center>
      </>
      )
  }else{
  return (
    <>
      <div className="detailcourses-container">
        <div className="bootcamp-wrapper">
          <div className="wrapper-top">
            <div className="detailcourses-banner">
              <img src={masterKey.bannerImg} alt="" />
              <div className="foot-banner">
                <div className="footbanner-text">{masterKey.bannerText}</div>
              </div>
            </div>
            <div className="training-program">
              <div className="program-heading">{masterKey.programHeading}</div>
              <div className="program-goal">{masterKey.programGoal}</div>
              <div className="costing-card">
                <CostingCard cost={masterKey.costCard} />
              </div>
              <div className="batches-card">
                <BatchCard data={masterKey.batchDetails} />
              </div>
            </div>
          </div>
          <div className="wrapper-bottom">
            <SideBar data={masterKey.courseDetails} />
          </div>
        </div>
        <div className="upcomingEvent" style={{ width: "90%" }}>
          <div className="learn-section-alt">
            <div className="section-heading flex-start">
              What you will Learn
            </div>
            <div className="learning-card">
              {/* Mapped by LearnData.json , Kindly Keep Minimum 2 Entries in Json File for Best Frontend Utilization */}
              {learnData[courseId].map((data, index) => {
                return <LearnCard data={data} key={index} />;
              })}
            </div>
          </div>
          <div className="benefit-card">
            {/* Mapped By: BenefitData.json
               Only Add Maximum 3-4 Benefits for design parameter 
               integrity Futher addition can break UI */}
            {benefitData.map((benefit, index) => {
              return <BenefitCard data={benefit} key={index} />;
            })}
          </div>
          {/* Instructor Section : Visual Keyword: Ridhi */}
          <div className="instructor-section">
            <div className="section-heading">Meet Your Instructors</div>
            <div className="card-section">
          {/* Mapped By InstructorData.json, *Scrollable Infinite Quantity */}
          {instructorData.map((instructor, index) => {
                return <InstructorCard data={instructor} key={index} />;
              })}
            </div> 
          </div>
          {/* End Instructor Section */}
          <ChooseUs links={masterKey.costCard} />
          <CertificateSection link={masterKey.costCard.enrollLink} img={masterKey.costCard.certificateImg}/>
          {/* Question Section , The One Just Before Footer with +,- Icons */}
          <div className="question-section">
            <div className="section-heading section-heading-ext">
              Frequently Asked Questions
            </div>
            <div className="question-wrap">
              {/* Mapped By QuestionData.json ,
                  Must Provide "answer" key to utilize design,
                 Infinite Addtions are Permitted  */}
              {questionData.map((question, index) => {
                return <QuestionCard data={question} key={index} />;
              })}
            </div>
            {/* End Question Section */}
          </div>
        </div>
      </div>
    </>
  );
}
};

export default DetailCourses;
