import React from "react";
import study from "../../assets/study.png";
import LearnCard from "./LearnCard.js";
import chooseUs from "./ChooseUs.json";
const ChooseUs = ({ links }) => {
  return (
    <>
      <div className="choose-us">
        <div className="chooseus-content">
          <div className="content-head">Choose Us With Benefits</div>
          <div
            className="learning-card"
          >
            {/* Mapped by ChooseUs.json , Kindly Keep Minimum 2 Entries in Json File for Best Frontend Utilization */}
            {chooseUs.map((data, index) => {
              return <LearnCard data={data} key={index} />;
            })}
            <div
              className="program-enroll"
              style={{ width: "100%", margin: "20px 0px" }}
            >
              <a href={links.enrollLink}>
                <button
                  type="button"
                  className="enrollBtn"
                  alt="Enroll"
                  style={{ width: "242px" }}
                >
                  Enroll Now
                </button>
              </a>
            </div>
            {/* <div
              className="syllabus-download"
              style={{ width: "100%", margin: "20px 0px" }}
            >
              <a href={links.downloadLink}>
                <button type="button" className="downloadBtn" alt="Download">
                  Download Syllabus
                </button>
              </a>
            </div> */}
          </div>
        </div>
        <div className="chooseus-banner">
          <div className="banner-image">
            <img src={study} alt="study" className="banner-headimg" />
            <div className="banner-head">Why Choose Us?</div>
            <div className="banner-subheading">
              We believe in quality-based learning rather than quantity, We
              focus mainly on project-driven learning and teach you all the
              skills required to work in the real world. Kickstart your learning
              with Code Sprint and fall in the loop of tech
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseUs;
