import React from 'react'
import "./DetailCourses.css"

const CourseCard = ({data}) => {
  return (
    <>
        <div className='course-card-alt'>
            <div className='course-name'>
              {data.heading}
            </div>
            <div className='course-duration'>
                {data.duration}
            </div>
        </div>
    </>
  )
}

export default CourseCard