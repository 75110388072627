import React from 'react'
import './event.css'
import data from './EventBanner.json'
// import arrowR from "../../../assets/arrow-right.png";



const EventBanner = () => {
  return (
    <>
      <div className="banner-section ebs">
        <div className="banner-info ebi">
          <div className="banner-heading">
            <img
              src={data[0].colllab_logo}
              alt="Girl at Study Table"
              className="banner-picture ep-alt"
            />
            <h1 className="banner-heading-text ebht">{data[0].title}</h1>
          </div>
          <p className="banner-subhead ebsh">
            Code Sprint's New Course Line-up for Management and Commerce Students. Coming soon with Courses like:
            <ul>
              <li>Business Analyst</li>
              <li>Power BI</li>
              <li>Tableau</li>
              <li>Excel</li>
            </ul>
            {/* <b className="esn">
              {data[0].guest}
            </b> */}
            {/* <span>
              <br />
              Date: {data[0].date}
              <br />
              Venue: {data[0].venue}
              <br />
              Timings: {data[0].timings}
            </span> */}
          </p>
          <button className="banner-button">
            <a href={data[0].link}>
              Coming Soon{" "}
              {/* <img src={arrowR} alt="Arrow Right" className="arrowR" /> */}
            </a>
          </button>
        </div>
        <div className="banner-frame ebf">
          <img
            src={data[0].speaker}
            alt="Girl at Study Table"
            className="banner-picture ep"
          />
        </div>
      </div>
    </>
  );
}

export default EventBanner
