import React , {useEffect} from "react";
import {useParams} from 'react-router-dom'
import "./PreviousBootcamp.css";
import previousBootcampData from './PreviousBootcampData.json'
import UpcomingEventCard from ".././Events/UpcomingEventCard";
import upcomingEventData from '../Events/UpcomingEventData.json'
import devImg from "../../assets/Development.png";
import ScheduleCard from "./ScheduleCard";
import InstructorCard from "./InstructorCard";
const PreviousBootcamp = () => {
    useEffect(()=>{
      window.scrollTo(0,0);
    },[])
    /*In order to fetch Courses Correctly , This Variable Id will be used 
    This Needs to be defined in Events Link in Every Course Card Correctly
    and needs the key to be existing in the DetailCourseData.json
    any mismatch in cordination of courseId and Data in DetailCourseData.json
    can lead to catastrophic Events to the website */
    let { eventId }= useParams(); //Datatype Should be Integer Only , Initally must be (Int) 0 
    //Range of detailCourseData.length() and Respective of Link Accessed
    let masterKey = previousBootcampData[eventId];
  if(masterKey===undefined){
      return(
      <>
      <center>
        <h1>Event is Not yet Published or There's Been a Mistake, Please Contact Admin!</h1>
      </center>
      </>
      )
  }else{
  return (
    <>
      <div className="Previous-Bootcamp">
        <div className="previousbootcamp-section">
          <div className="previousbootcamp-banner">
            <div className="banner-left">
              <div className="banner-heading">
                  FREE {masterKey.videos.length} DAYS LONG BOOTCAMP
                </div>
                <div className="banner-sub">
                  {masterKey.eventDetails.eventTitle}
                </div>
                <div className="banner-foot">
                  by {masterKey.instructor.name}
                  <br />
                  Streamed on {masterKey.eventDetails.date}
                </div>
              </div>
            <div className="banner-right">
              <img src={devImg} alt="Development" className="dev-img" />
            </div>
          </div>
        </div>
        <div className="previousbootcamp-container">
          <div className="bootcamp-wrapper-alt">
            <div className="wrapper-bottom">
              <InstructorCard data={masterKey} />
              <div className="program-schedule">
                <div className="card-heading">Program Schedule</div>
                <div className="schedule-listing">
                  {masterKey.videos.map((video, index) => {
                    return <ScheduleCard data={video} key={index} />;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="upcomingEvent">
            <div className="card-heading">
              More Upcoming Details
            </div>
            <div className="events-listing">
            {upcomingEventData.map((course,index)=>{
              return <UpcomingEventCard data={course} key={index}/>
            })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
};

export default PreviousBootcamp;
