import React from "react";
// import Star from '../../assets/rating.png'
// import Heart from '../../assets/save.png'
const StoreItem = ({ data }) => {
  return (
    <>
      <div className="store-card">
        <img src={data.productImage} className="item-image" alt="" />

        {/* Alternative Approach For Item Image with Rating and Icons : 
        Check Line 59 of StoreSection.css For Image Link
        Remove Comments from Imported Images Also*/}

        {/* <div className="item-image">
          <div className="item-rating">
            <img src={Star} alt="Rating Icon" /> {data.rating}
          </div>
          <div className="item-like">
            <img src={Heart} alt="Heart Icon" />
          </div>
        </div> */}
        <div className="item-heading">{data.heading}</div>
        <div className="item-category">{data.category}</div>
        <div className="item-price">
          {/* <div className="item-discount">
          <s>{data.discount}</s>
        </div> */}
          <div className="item-sale-price">₹{data.price}</div>
        </div>
        <button className="purchase-button">Buy Now</button>
      </div>
    </>
  );
};

export default StoreItem;
