import React from "react";
import "./PreviousBootcamp";
import calendar from "../../assets/calendar(1).png";
import Details from "./Details";
import detail1 from "../../assets/detail1.png";
import detail2 from "../../assets/detail2.png";
import detail3 from "../../assets/detail3.png";
const SideBar = ({msk}) => {
  return (
    <>
      <div className="vertical-bar">
        <div className="vertical-header">
          <div className="calendar-img">
            <img src={calendar} className="calendarImg" alt="calendar" />
          </div>
          <div className="header-content">
            <div className="content-date">{msk.date}</div>
            <div className="content-time">{msk.time} IST Onwards</div>
          </div>
        </div>
        <div className="sidebar-detail-alt">
          <div className="detail-heading">Event Details</div>
          <div className="vertical-details-listing">
            <Details img={detail1} heading="Type" subheading={msk.eventCategory} />
            <Details img={detail2} heading="Location" subheading={msk.deliveryMode} />
            <Details img={detail1} heading="Prerequisites" subheading={msk.requisites}/>
            <Details img={detail3} heading="Certification" subheading={msk.isCertifiable}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
